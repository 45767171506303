import { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Timer } from '@material-ui/icons'
import { MenuRT, FooterRT } from '../components/SubMenus'
import { MUITable, SelectColumnFilter } from '../components/MUITable'
import { useConfig, useRaces, useStateWithLocalStorage } from '../hooks'
import { msToTime, pointsToTime } from '../utils/timeConverter'
import { filterRTWithPosition, filterOVWithPosition } from '../utils/filters'

const useStyles = props =>
	makeStyles(theme => ({
		container: {
			height: '100%',
		},
		menu: {
			height: `${props.isMix ? '72px' : '108px'}`,
		},
		table: { height: `calc((var(--vh, 1vh) * 100) - ${props.isMix ? '184px' : '220px'})` },
		footer: {
			height: '48px',
		},
		content: {
			height: 'auto',
		},
		total: {
			//color: theme.palette.primary.main
		},
		numberSpan: {
			//display: 'inline-block',
			textAlign: 'right',
			width: '40px',
		},
	}))

const RANKING_RT_QUERY = gql`
	query rankingRTQuery($groupID: ID) {
		rankingRT(groupID: $groupID) {
			position
			raceNumber
			teamName
			isAuthentic
			coefficientDisplay
			groupDisplay
			classDisplay
			model
			avg
			avgText
			race {
				text
			}
			period
			plateau
			isCompleted
			refTimeCircuit
			results
			cones
			gates
			laps
			total
			timeRT
			difPrevious
			difFirst
		}
	}
`

const RANKING_RTOV_QUERY = gql`
	query rankingRTOVQuery($groupID: ID) {
		rankingRTOV(groupID: $groupID) {
			position
			raceNumber
			teamName
			isAuthentic
			coefficientDisplay
			model
			avg
			avgText
			period
			jokersOV
			totalOV
			difPrevious
			difFirst
		}
	}
`

function RankingRT() {
	const { config } = useConfig()
	const { races } = useRaces()
	const {
		pollInterval,
		rtPrefix,
		isTimeTotal,
		isDecimal,
		mixDisplayRaces: isMix,
		isDisplayGroup,
		isDisplayAVG,
		isPartialOV,
		isDisplayCoef,
		rallyType,
		isAuthentic,
	} = config

	const classes = useStyles({ isMix })()
	const [selectedRace, setSelectedRace] = useStateWithLocalStorage('selectedRace')
	const localStorageKey = isMix ? 'selectedRT' : `selectedRT${selectedRace}`
	const [selectedRT, setSelectedRT] = useStateWithLocalStorage(localStorageKey)
	const [race, setRace] = useState(null)
	const [stages, setStages] = useState([])
	const [isData, setIsData] = useState(false)
	const [rankingRT, setRankingRT] = useState([])
	const [rankingRTOV, setRankingRTOV] = useState([])
	const [viewTypeRT, setViewTypeRT] = useStateWithLocalStorage('viewTypeRT', 'summary')
	const [propsRT, setPropsRT] = useState({})

	const {
		loading: rankingRTLoading,
		error: rankingRTError,
		data: rankingRTData,
	} = useQuery(RANKING_RT_QUERY, {
		skip: !selectedRT,
		pollInterval: pollInterval || 60000,
		variables: { groupID: selectedRT },
	})
	const {
		loading: rankingRTOVLoading,
		error: rankingRTOVError,
		data: rankingRTOVData,
	} = useQuery(RANKING_RTOV_QUERY, {
		skip: !selectedRT || !isPartialOV,
		pollInterval: pollInterval || 60000,
		variables: { groupID: selectedRT },
	})

	useEffect(() => {
		if (!rankingRTLoading && rankingRTData) {
			setRankingRT(rankingRTData.rankingRT)
			setIsData(true)
		}
		if (!rankingRTOVLoading && rankingRTOVData) {
			setRankingRTOV(rankingRTOVData.rankingRTOV)
		}
	}, [rankingRTLoading, rankingRTOVLoading, rankingRTData, rankingRTOVData])

	useEffect(() => {
		if (selectedRace) {
			const newRace = races.find(race => race.id.toString() === selectedRace.toString())
			setRace(newRace)
		}
	}, [selectedRace, races])

	if (rankingRTLoading || rankingRTOVLoading) return 'Loading...'
	if (rankingRTError || rankingRTOVError)
		return `Error! ${rankingRTError && rankingRTError.message} ${
			rankingRTOVError && rankingRTOVError.message
		}`

	const columnsRT = [
		{
			Header: <FormattedMessage id="position" />,
			accessor: 'position',
			sticky: 'left',

			maxWidth: 30,
			Cell: ({ value }) => (
				<span className={classes.numberSpan}>
					<strong>{value || '-'}</strong>
				</span>
			),
		},
		{
			Header: '#',
			accessor: 'raceNumber',
			sticky: 'left',
			maxWidth: 30,
			Cell: ({ value }) => <span className={classes.numberSpan}>{value}</span>,
		},
		{
			Header: <FormattedMessage id="crew" />,
			id: 'teamName',
			maxWidth: 150,
			//sticky: viewTypeRT === 'detailed' ? 'left' : null,
			accessor: row =>
				`${row.teamName}<br /><span style="font-size:70%;padding-left:20px;">${row.model}</span>`,
			Cell: ({ cell }) => <div dangerouslySetInnerHTML={{ __html: cell.value }} />,
		},
	]

	if (isAuthentic) {
		columnsRT.push({
			Header: () => null,
			accessor: 'isAuthentic',
			Cell: ({ cell }) =>
				cell.value === true ? <Timer style={{ color: '#757575', fontSize: 'medium' }} /> : '',
			maxWidth: 25,
		})
	}

	if (viewTypeRT === 'detailed') {
		/*	if (config.isDisplayCarRT) {
			columnsRT.push({
				Header: <FormattedMessage id="car" />,
				accessor: 'model',
			})
		}*/

		if (isMix && races.length > 1) {
			columnsRT.push({
				Header: <FormattedMessage id="race" />,
				accessor: 'race.text',
				width: 80,
			})
		}

		if (propsRT.isCRS) {
			columnsRT.push({ Header: 'Cl', accessor: 'classDisplay', maxWidth: 50 })
			//columnsRankingRT.push({ Header: 'Pos', accessor: 'classPos', maxWidth: 50 })
		}

		if (isDisplayGroup && rallyType !== 'TAO') {
			columnsRT.push({ Header: 'Gr', accessor: 'groupDisplay', maxWidth: 20 })
		}

		if (isDisplayAVG && (isMix || race?.isAvg)) {
			columnsRT.push({ Header: <FormattedMessage id="avg" />, accessor: 'avgText', maxWidth: 60 })
		}

		if (isDisplayCoef === true && (isMix || race?.isCoef)) {
			columnsRT.push({
				Header: <FormattedMessage id="coef" />,
				accessor: 'coefficientDisplay',
				maxWidth: 50,
				Cell: ({ cell }) => <i>{cell.value}</i>,
			})
		}

		if (rallyType === 'TAO') {
			if (propsRT.isCircuit) {
				columnsRT.push({
					Header: <FormattedMessage id="period" />,
					id: 'period',
					accessor: 'period',
					Filter: SelectColumnFilter,
					filter: filterRTWithPosition,
				})

				columnsRT.push({
					Header: <FormattedMessage id="plateau" />,
					id: 'plateau',
					accessor: 'plateau',
					Filter: SelectColumnFilter,
					filter: filterRTWithPosition,
				})
			} else {
				columnsRT.push({
					Header: <FormattedMessage id="period" />,
					id: 'period',
					accessor: 'period',
					Filter: SelectColumnFilter,
					filter: filterRTWithPosition,
					width: 80,
				})
			}
		}

		if (propsRT.lapsGroups > 1) {
			for (let i = 0; i < propsRT.lapsGroups; i++) {
				columnsRT.push({
					Header: `Ref Time ${i + 1}`,
					id: `refTimeCircuit${i}`,
					accessor: row =>
						pointsToTime(row.refTimeCircuit[i], isDecimal, 'refTime', propsRT.precision),
					minWidth: 80,
				})

				for (const [j, stage] of propsRT.stages.entries()) {
					if (stages) {
						if (stage.lapGroup === i + 1) {
							const value = stages[j]
							const colWidth =
								value.substring(0, 4) === 'Dif.' || value.substring(0, 2) === 'CL' ? 120 : 70
							columnsRT.push({
								Header: value,
								id: `tp${value}`,
								accessor: 'results[' + j + ']',
								width: colWidth,
								maxWidth: 200,
							})
						}
					}
				}
			}
		} else {
			if (propsRT.isCircuit === true && !race?.isScratch) {
				columnsRT.push({
					Header: 'Ref Time',
					id: 'refTimeCirctuit',
					accessor: row =>
						pointsToTime(row.refTimeCircuit, isDecimal, 'refTime', propsRT.precision),
					minWidth: 80,
				})
			}

			if (propsRT.isCircuit === true && race?.isScratch) {
				columnsRT.push({
					Header: 'Laps',
					id: 'laps',
					accessor: 'laps',
					minWidth: 80,
				})
			}

			if (rallyType === 'LBB') {
				columnsRT.push({
					Header: 'Time',
					id: 'timeRT',
					maxWidth: 80,
					accessor: row => msToTime(row.timeRT, isDecimal),
				})
			}

			if (stages && !race?.isScratch) {
				for (const [i, value] of stages.entries()) {
					const colWidth =
						value.substring(0, 6) === 'Target' || value.substring(0, 2) === 'CL' ? 60 : 60
					columnsRT.push({
						Header: <span className={classes.numberSpan}>{value}</span>,
						accessor: 'results[' + i + ']',
						width: colWidth,
						id: value,
						Cell: ({ cell }) => <span className={classes.numberSpan}>{cell.value}</span>,
					})

					if (propsRT.isNT) {
						columnsRT.push({
							Header: 'Difference',
							id: `difDistance`,
							accessor: row => (row.results[0] > 0 ? row.results[0] - propsRT.distance : '-'),
							width: colWidth,
							maxWidth: 200,
						})
					}
				}
			}
		}
		if (propsRT.isCones) {
			columnsRT.push({
				Header: 'Cones',
				accessor: 'cones',
				maxWidth: 70,
			})
		}

		if (propsRT.isGates) {
			columnsRT.push({
				Header: 'Gates',
				accessor: 'gates',
				maxWidth: 70,
			})
		}
	}

	columnsRT.push({
		Header: (
			<span className={classes.numberSpan}>
				{race?.isScratch ? <FormattedMessage id="time" /> : <FormattedMessage id="total" />}
			</span>
		),
		id: 'total',
		accessor: row =>
			rallyType === 'TAO' && !row.isCompleted
				? 'NT'
				: isTimeTotal
				? row.total === 0
					? isDecimal
						? '00:00.0'
						: '00:00'
					: pointsToTime(row.total, isDecimal, 'total', propsRT.precision)
				: race?.isCoef || isDecimal
				? row.total.toFixed(2)
				: row.total,
		maxWidth: viewTypeRT === 'detailed' ? 60 : 1000,
		//sticky: 'right',
		Cell: row => (
			<span className={classes.numberSpan}>
				<strong>{row.value}</strong>
			</span>
		),
	})
	if (viewTypeRT === 'detailed') {
		columnsRT.push({
			Header: <FormattedMessage id="difference" />,
			accessor: 'difPrevious',
			maxWidth: isTimeTotal ? 50 : 50,
		})
		columnsRT.push({
			Header: <FormattedMessage id="differenceFirst" />,
			accessor: 'difFirst',
			//maxWidth: isTimeTotal ? 50 : 50,
		})
	}
	const columnsRTOV = [
		{
			Header: <FormattedMessage id="position" />,
			accessor: 'position',
			maxWidth: 30,
			sticky: 'left',
			Cell: ({ value }) => (
				<span className={classes.numberSpan}>
					<strong>{value || '-'}</strong>
				</span>
			),
		},
		{
			Header: '#',
			accessor: 'raceNumber',
			maxWidth: 30,
			sticky: 'left',
			Cell: ({ value }) => <span>{value}</span>,
		},
		{
			Header: <FormattedMessage id="crew" />,
			id: 'teamName',
			maxWidth: 150,
			accessor: row => `${row.teamName}<br /><span style="font-size:70%">${row.model}</span>`,
			Cell: ({ cell }) => <div dangerouslySetInnerHTML={{ __html: cell.value }} />,
			//minWidth: 175,
			//maxWidth: 200,
		},
	]

	if (isAuthentic) {
		columnsRTOV.push({
			Header: () => null,
			accessor: 'isAuthentic',
			Cell: ({ cell }) =>
				cell.value === true ? <Timer style={{ color: '#757575', fontSize: 'medium' }} /> : '',
			maxWidth: 25,
		})
	}

	if (isDisplayAVG && (isMix || race?.isAvg)) {
		columnsRTOV.push({ Header: <FormattedMessage id="avg" />, accessor: 'avgText', maxWidth: 60 })
	}

	if (rallyType === 'TAO') {
		columnsRTOV.push({
			Header: <FormattedMessage id="period" />,
			id: 'period',
			accessor: 'period',
			Filter: SelectColumnFilter,
			filter: filterOVWithPosition,
			width: 80,
		})
	}

	if (propsRT.isJokers) {
		columnsRTOV.push({
			Header: 'Jokers',
			id: 'jokers',
			accessor: row =>
				isTimeTotal
					? row.jokersOV === 0
						? isDecimal
							? '00:00.0'
							: '00:00'
						: pointsToTime(row.jokersOV, isDecimal, 'jokers')
					: Number(parseFloat(row.jokersOV).toPrecision(12)),
			maxWidth: isTimeTotal ? 120 : 50,
			//sticky: 'right',
			Cell: row => <strong className={classes.total}>{row.value}</strong>,
		})
	}

	columnsRTOV.push(
		{
			Header: <FormattedMessage id="total" />,
			id: 'total',
			maxWidth: 50,
			accessor: row =>
				isTimeTotal
					? row.totalOV === 0
						? isDecimal
							? '00:00.0'
							: '00:00'
						: pointsToTime(row.totalOV - row.jokersOV, isDecimal, 'total')
					: (row.totalOV - row.jokersOV).toFixed(2),
			//maxWidth: isTimeTotal ? 120 : 30,
			//sticky: 'right',
			Cell: row => <strong className={classes.total}>{row.value}</strong>,
		},
		{
			Header: <FormattedMessage id="difference" />,
			id: 'difPrevious',
			accessor: 'difPrevious',
			maxWidth: isTimeTotal ? 50 : 50,
		},
		{
			Header: <FormattedMessage id="differenceFirst" />,
			id: 'difFirst',
			accessor: 'difFirst',
			//maxWidth: isTimeTotal ? 120 : 80,
		}
	)

	const rankingRTTable =
		viewTypeRT === 'rankingRTOV' ? (
			<MUITable columns={columnsRTOV} data={rankingRTOV} type="rankingRT" isMix={isMix} />
		) : (
			<MUITable columns={columnsRT} data={rankingRT} type="rankingRT" isMix={isMix} />
		)

	return (
		<div className={classes.container}>
			<div className={classes.menu}>
				<MenuRT
					rankingSelectedRT={selectedRT}
					setRankingSelectedRT={setSelectedRT}
					rankingSelectedRace={selectedRace}
					setRankingSelectedRace={setSelectedRace}
					setRankingStages={setStages}
					setRankingPropsRT={setPropsRT}
					isData={isData}
				/>
			</div>

			{rankingRT && selectedRT && (race || isMix) && propsRT.isActive && propsRT.isOnline && (
				<>
					<div className={classes.table}>{rankingRTTable}</div>
					<div className={classes.footer}>
						<FooterRT
							viewTypeRT={viewTypeRT}
							setViewTypeRT={setViewTypeRT}
							className={classes.footerRT}
							rtPrefix={rtPrefix}
							//style={{ position: 'sticky' }}
						/>
					</div>
				</>
			)}
		</div>
	)
}

export default RankingRT
