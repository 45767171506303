export const matomoSites = [
	{ id: 1, code: 'JBTC2020' },
	{ id: 2, code: 'JBTC2021' },
	{ id: 3, code: 'JBTC2022' },
	{ id: 4, code: 'AHT2022' },
	{ id: 5, code: 'TGC2022' },
	{ id: 6, code: 'OVV2022' },
	{ id: 7, code: 'EBR2022' },
	{ id: 8, code: 'RCG2022' },
	{ id: 9, code: 'C1K2022' },
]
